import { BreakpointObserver } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FilterGroup, FilterOption, reduceFilterGroupToSelectedOptions } from '@app/components/filter/filter.component';
import { QueryFetcher } from '@app/models/core/query-fetcher.model';
import { Employee } from '@app/models/employee/employee.model';
import { TimeOffDay } from '@app/models/time-off-v3/time-off-day.model';
import { TimeOffRequestBlackoutPeriod } from '@app/models/time-off-v3/time-off-request-blackout-period.model';
import { TimeOffRequest } from '@app/models/time-off-v3/time-off-request.model';
import { AuthService, NotifyService } from '@app/services';
import { CalendarView } from '@common/views/calendar/calendar.view';
import { CalendarEvent } from '@models/common/calendar-event.model';
import { Department } from '@models/company/department.model';
import { Office } from '@models/company/office.model';
import { HolidayCalendarEvent } from '@models/time-off/holiday-calendar-event.model';
import { TimeOffRequestCalendarEvent } from '@models/time-off/time-off-request-calendar-event.model';
import { addWeeks, endOfMonth, subWeeks } from 'date-fns';
import { chunk, flatten } from 'lodash-es';

const EMPLOYEES_PER_REQUEST = 50;

@Component({
    templateUrl: '../calendar.template.html',
    styleUrls: ['../calendar.style.scss'],
})
export class TimeOffCalendarView extends CalendarView {
    eventTypes = [HolidayCalendarEvent, TimeOffRequestCalendarEvent];
    usesFilters = true;

    departmentFilterGroup: FilterGroup = {
        label: 'time-off-v3.calendar.departments',
        options: [],
    };
    officeFilterGroup: FilterGroup = {
        label: 'time-off-v3.calendar.offices',
        options: [],
    };
    statusFilterGroup: FilterGroup = {
        label: 'time-off-v3.calendar.status',
        options: [
            { label: 'time-off-v3.calendar.approved', value: 'approved', selected: true },
            { label: 'time-off-v3.calendar.denied', value: 'denied', selected: false },
            { label: 'time-off-v3.calendar.pending', value: 'pending', selected: false },
        ],
    };

    filterGroups = [this.departmentFilterGroup, this.officeFilterGroup, this.statusFilterGroup];

    constructor(
        auth: AuthService,
        router: Router,
        private notify: NotifyService,
        breakpointObserver: BreakpointObserver
    ) {
        super(auth, router, breakpointObserver);
    }

    onClickEvent(event: CalendarEvent): void {
        const isAdmin = this.auth.can('manageAll') && this.auth.can('view.timeOffRequest');

        if (isAdmin && event.path) {
            this.router.navigate(event.path);
        }
    }

    prepareFilters(): void {
        Department.param('company', this.auth.company?.id)
            .all()
            .then(([departments]) => {
                for (const department of departments) {
                    this.departmentFilterGroup.options.push({
                        label: department.name,
                        value: department.id,
                        selected: false,
                    });
                }
            });

        Office.param('company', this.auth.company?.id)
            .all()
            .then(([offices]) => {
                for (const office of offices) {
                    this.officeFilterGroup.options.push({
                        label: office.name,
                        value: office.id,
                        selected: false,
                    });
                }
            });
    }

    protected moldFilters(): void {
        const hasValidStatusFilter = reduceFilterGroupToSelectedOptions(this.statusFilterGroup).length;

        if (!hasValidStatusFilter) {
            const approvedOption = this.statusFilterGroup.options.find(
                (filterGroup) => filterGroup.value === 'approved'
            );
            if (approvedOption) {
                approvedOption.selected = true;
            }
        }
    }

    protected async loadEvents(): Promise<void> {
        this.isLoading = true;

        if (!this.auth.employee || !this.auth.company) {
            return;
        }

        try {
            const workSchedule = (
                await Employee.param('company', this.auth.company.id).with('workSchedule').find(this.auth.employee.id)
            ).workSchedule;

            const startAt = subWeeks(this.date, 1);
            const endAt = addWeeks(endOfMonth(this.date), 1);

            const [timeOffRequests] = await this.getTimeOffRequestQuery()
                .where('startAt', { to: endAt })
                .where('endAt', { from: startAt })
                .all();

            const employeeIds = new Set(timeOffRequests.map((t: TimeOffRequest) => t.employeeId));

            const employeePromises = chunk([...employeeIds], EMPLOYEES_PER_REQUEST).map(
                (employeeIdsChunk: number[]) => {
                    return Employee.param('company', this.auth.company?.id).whereIn('id', employeeIdsChunk).all();
                }
            );

            const employees = flatten((await Promise.all(employeePromises)).map((res) => res[0]));

            timeOffRequests.forEach(
                (t: TimeOffRequest) => (t.employee = employees.find((e: Employee) => e.id === t.employeeId))
            );

            const [timeOffDays] = await TimeOffDay.where('workScheduleId', workSchedule.id)
                .where('date', { from: startAt, to: endAt })
                .get();

            const [timeOffBlackoutPeriods] = await TimeOffRequestBlackoutPeriod.where('workScheduleId', workSchedule.id)
                .where('startAt', { to: endAt })
                .where('endAt', { from: startAt })
                .get();

            const timeOffRequestEvents = timeOffRequests.map((request: TimeOffRequest) =>
                request.toCalendarEvent(this.auth)
            );
            const events = [...timeOffDays, ...timeOffBlackoutPeriods].map((entity) => entity.toCalendarEvent());

            this.events = [...events, ...timeOffRequestEvents];
            this.scopeEvents();
        } catch (e) {
            this.notify.error((e as { message: string }).message);
        }

        this.isLoading = false;
    }

    private getTimeOffRequestQuery(): QueryFetcher {
        const query = TimeOffRequest.with(['timeOffPolicy.timeOffType']);

        const selectedStatuses = reduceFilterGroupToSelectedOptions(this.statusFilterGroup).map(
            (option: FilterOption) => option.value
        );

        if (selectedStatuses.length) {
            query.whereIn('status', selectedStatuses);
        }

        const selectedDepartmentIds = reduceFilterGroupToSelectedOptions(this.departmentFilterGroup).map(
            (option: FilterOption) => option.value
        );

        if (selectedDepartmentIds.length) {
            query.whereIn('departmentId', selectedDepartmentIds);
        }

        const selectedOfficeIds = reduceFilterGroupToSelectedOptions(this.officeFilterGroup).map(
            (option: FilterOption) => option.value
        );

        if (selectedOfficeIds.length) {
            query.whereIn('officeId', selectedOfficeIds);
        }

        return query;
    }
}
