export const NOTIFICATION_ICONS = {
    general: 'general',
    timeOff: 'timeOff',
    performance: 'performance',
    recruiting: 'applicantTracker',
    documents: 'documentsNotification',
    feedback: 'goalsAndFeedback',
    review: 'review',
    survey: 'survey',
    goal: 'goalsAndFeedback',
    announcement: 'announcement',
    benefits: 'benefits',
    timeTracking: 'timeTracking',
    changeRequest: 'changeRequest',
    offerLetter: 'offerLetter',
    training: 'training',
    reminder: 'announcement',
} as const;

export type NotificationCategoryType = (typeof NOTIFICATION_ICONS)[keyof typeof NOTIFICATION_ICONS];
