import { Model } from '@app/interfaces/model.interface';

export abstract class UpdateView {
    /**
     * Entity Save Event
     * You can pass a reference to the model
     * or use your component scoped model
     */
    abstract onSave(model?: Model): void;
}
