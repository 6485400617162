import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { CalendarView } from '@common/views';
import { TimeOffCalendarView } from '@common/views';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { PlatformModule, momentAdapterFactory } from '@app/platform.module';
import { NotificationViewerItemComponent } from './views/notification-viewer-item/notification-viewer-item.component';
import { TranslateModule, TranslateParser } from '@ngx-translate/core';
import { TranslateParserDebuggerService } from '@app/services/language/translate-parser-debugger.service';

export const Imports = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    PlatformModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory }),
    TranslateModule.forChild({
        parser: { provide: TranslateParser, useClass: TranslateParserDebuggerService },
    }),
];

@NgModule({
    imports: [...Imports],
    declarations: [CalendarView, TimeOffCalendarView, NotificationViewerItemComponent],
    exports: [CalendarView, TimeOffCalendarView, CalendarModule, NotificationViewerItemComponent, TranslateModule],
})
export class AppCommonModule {
    static forRoot(): ModuleWithProviders<AppCommonModule> {
        return {
            ngModule: AppCommonModule,
        };
    }
}
