import { Injectable } from '@angular/core';
import { TranslateDefaultParser } from '@ngx-translate/core';
import { TranslateDebuggerStoreService } from '@app/services/language/translate-debugger-store.service';

@Injectable({
    providedIn: 'root',
})
export class TranslateParserDebuggerService extends TranslateDefaultParser {
    readonly LOCAL_STORAGE_KEY = 'language-debug';

    private debug = false;

    constructor(private store: TranslateDebuggerStoreService) {
        super();
        this.debug = this.store.isDebugEnabled();
    }

    getValue(target: any, key: string): any {
        return this.debug ? '{' + key + '}' : super.getValue(target, key);
    }
}
