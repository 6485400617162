import { Model } from '@app/models/core/base.model';
import { HotTableRegisterer } from '@handsontable/angular';
import { environment } from 'environments/environment';
import Handsontable from 'handsontable';
import { CellChange, ChangeSource } from 'handsontable/common';
import { TextEditor } from 'handsontable/editors';
import { ColumnSettings, GridSettings } from 'handsontable/settings';

const CELL_CHANGE_ROW_INDEX = 0;
const CELL_CHANGE_CURRENT_VALUE_INDEX = 3;
const MAX_CONTAINER_HEIGHT_PERCENTAGE_NORMAL = 0.6;
const MAX_CONTAINER_HEIGHT_PERCENTAGE_FULLSCREEN = 0.8;

/*
 * These keys are number keys, and movement keys, used in the numeric input.
 * We do not show input from invalid key presses.
 * These key codes are numbers, decimals and movement keys.
 */
const allowedKeys = [
    8, 13, 27, 35, 36, 37, 38, 39, 40, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99, 100, 101, 102, 103,
    104, 105, 110, 190, 189,
];

function filterNoOpChanges([, , previousValue, currentValue]: CellChange): boolean {
    return previousValue !== currentValue;
}

/**
 * Sets a fixed height for the table container
 * This allows the table to scroll properly using its built-in logic
 */
function resizeContainerHeight(instance: Handsontable, isFullScreen = false): void {
    const additionalHeight = 40;
    const heightOfOneRowInPixels = 25;
    const numberOfRows = instance.getSourceData().length;
    const adjustedHeight = numberOfRows * heightOfOneRowInPixels + additionalHeight;

    const windowHeight = window.innerHeight;
    const maxHeight =
        windowHeight *
        (isFullScreen ? MAX_CONTAINER_HEIGHT_PERCENTAGE_FULLSCREEN : MAX_CONTAINER_HEIGHT_PERCENTAGE_NORMAL);

    const container = instance.rootElement.parentElement.parentElement;
    const appropriateHeight = maxHeight < adjustedHeight ? maxHeight : adjustedHeight;

    container.style.height = `${appropriateHeight}px`;
}

export const baseSettings: GridSettings = {
    allowInsertColumn: false,
    allowInsertRow: false,
    allowInvalid: false,
    autoColumnSize: true,
    colHeaders: true,
    licenseKey: environment.handsOnTable,
    stretchH: 'all',
    // these two lines stop data from showing up in the wrong cells
    // a hands on table bug
    renderAllRows: true,
    viewportColumnRenderingOffset: 70,
};

export interface HandsontableableComponent {
    afterChange(changes: CellChange[]): void;
    changeQueue: CellChange[];
    columns: ColumnSettings[];
    dataset: any[];
    getHotInstance(): Handsontable;
    highlightError(index: number, column: string): void;
    hotId: string;
    hotRegisterer: HotTableRegisterer;
    onSaveFailure(model: Model, change: CellChange): void;
    saveChange(change: CellChange): Promise<any>;
    saveChanges(changes: CellChange[]): void;
    saving: boolean;
    settings: { [key: string]: any };
}

export function onHotChange(changes: CellChange[], source: ChangeSource): void {
    const initialLoad = source === 'loadData';

    if (initialLoad) {
        const instance = this.getHotInstance();
        resizeContainerHeight(instance, this.fullscreen);
        instance.render();
        return;
    }

    this.changeQueue = [...this.changeQueue, ...changes.filter(filterNoOpChanges)];
}

export function recalculateTableDimensions(): void {
    setTimeout(() => {
        if (!this.getHotInstance()) {
            return;
        }

        resizeContainerHeight(this.getHotInstance(), this.fullscreen);
        this.getHotInstance().render();
        this.getHotInstance().getPlugin('autoColumnSize').recalculateAllColumnsWidth();
    });
}

export function convertEmptyAndNegativesToZero(changes: CellChange[]): void {
    changes.forEach((change) => {
        const newValue = change[CELL_CHANGE_CURRENT_VALUE_INDEX];
        if (newValue < 0 || !newValue) {
            change[CELL_CHANGE_CURRENT_VALUE_INDEX] = 0;
        }
    });
}

export function startChangeQueue(component: HandsontableableComponent): void {
    setInterval(async () => {
        if (component.changeQueue.length && !component.saving) {
            const changesToSave = [...component.changeQueue];
            component.changeQueue = [];
            component.saveChanges(changesToSave);
        }
    }, 100);
}

/**
 * Group changes by row
 * and then persist changes from the same row in series
 */
export function saveChanges(changes: CellChange[]): void {
    const groupedChanges: CellChange[][] = this.dataset.map(() => []);
    changes.forEach((change) => groupedChanges[change[CELL_CHANGE_ROW_INDEX]].push(change));

    const promises = groupedChanges.map(async (changes: CellChange[]): Promise<void> => {
        for (const change of changes) {
            await this.saveChange(change);
        }
    });

    this.saving = true;
    Promise.all(promises).then(() => {
        this.saving = false;
    });
}

/**
 * Revert the model, but not the dataset (table).
 * We want the model to be valid so that subsequent changes work.
 * but we want to dataset to be invalid so users know what to fix
 */
export function onSaveFailure(model: Model, change: CellChange): void {
    const [index, column, previousValue] = change;
    model[column.toString()] = previousValue;
    this.highlightError(index, column.toString());
}

export function getHotInstance(): Handsontable {
    return this.hotRegisterer.getInstance(this.hotId);
}

export function highlightError(index: number, column: string): void {
    const hotInstance = this.getHotInstance();
    const rowMetaData = hotInstance.getCellMetaAtRow(index);
    const cellMetaData = rowMetaData.find(({ prop }) => prop === column);
    cellMetaData.valid = false;
    hotInstance.render();
}

export class NumericEditor extends TextEditor {
    createElements(): void {
        super.createElements();

        this.TEXTAREA = document.createElement('input');
        this.TEXTAREA.setAttribute('data-hot-input', 'true');
        this.textareaStyle = this.TEXTAREA.style;
        Handsontable.dom.empty(this.TEXTAREA_PARENT);
        this.TEXTAREA_PARENT.appendChild(this.TEXTAREA);

        // only allow numbers
        this.TEXTAREA.addEventListener('keydown', function (event) {
            // allow copy and paste
            if (event.ctrlKey || event.metaKey) {
                return;
            }

            const invalidKeyPress = !allowedKeys.includes(event.which);
            const isDecimal = event.which === 110 || event.which === 190;
            const decimalAlreadyInValue = (this as HTMLInputElement).value.includes('.');

            if (invalidKeyPress || (isDecimal && decimalAlreadyInValue)) {
                event.preventDefault();
            }
        });
    }
}

export function beforePasteConvertToNumber(data: (string | number)[][]): void {
    data.forEach((row: (string | number)[]) => {
        row.forEach((cell: string | number, cellIndex: number) => {
            if (typeof cell === 'string') {
                row[cellIndex] = cell.replace(/[^\d.]/g, '');
            }
        });
    });
}
