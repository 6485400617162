import { DeprecatedModalFormComponent } from '@app/components';
import { Model } from '@app/interfaces/model.interface';
import { BaseForm } from '@app/modules/forms/base.form';

const CUSTOM_DATA_GROUP = 'CUSTOM_DATA_GROUP';

export type CUSTOM_DATA_GROUP_DIALOG = typeof CUSTOM_DATA_GROUP;

export interface ShowView {
    /**
     * Custom Data Group Save Event
     * You can pass a reference to the model
     * or use your component scoped model
     */
    onDataFieldsSave?(model?: Model): void;
    /**
     * Entity Delete Event
     * You can pass a reference to the model
     * or use your component scoped model
     */
    onDelete?(model?: Model): void;
}

export abstract class ShowView {
    currentlyOpenDialog?: string | CUSTOM_DATA_GROUP_DIALOG;
    editFieldGroup!: string;

    closeDialog(): void {
        this.currentlyOpenDialog = undefined;
    }

    onDataFieldEdit(group: string, modal?: DeprecatedModalFormComponent): void {
        this.editFieldGroup = group;
        // modal.show is deprecated. It is still used on employee show-view, but not on account show-view
        // Once employee show-view is also updated, this if statement can be removed
        if (modal) {
            modal.show();
        } else {
            this.currentlyOpenDialog = CUSTOM_DATA_GROUP;
        }
    }

    submitForm(form?: BaseForm, isCustomGroup = false): void {
        if (!form) {
            return;
        }

        form.submit();

        if (!form.valid) {
            return;
        }

        // isCustomGroup is used when the user has edited a custom field group
        if (isCustomGroup) {
            this.onDataFieldsSave?.(form);
        } else {
            this.onSave(form);
        }
    }

    /**
     * Entity Edit Save Event
     * You can pass a reference to the model
     * or use your component scoped model
     */
    abstract onSave(model?: Model): void;

    /**
     * Entity Edit Cancel Event
     * You can pass a reference to the model
     * or use your component scoped model
     */
    abstract onCancel(model?: Model): void;
}
