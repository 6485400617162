import { CalendarEvent } from '@models/common/calendar-event.model';
import { Employee } from '@models/employee/employee.model';
import { format, getHours, isSameDay, isSameMonth, subMinutes } from 'date-fns';
import { QueryFetcher } from '../core/query-fetcher.model';

export class TimeOffRequestCalendarEvent extends CalendarEvent {
    protected static _resource = 'timeOff/companies/:company/requests/calendar';

    protected static entityFilters = {
        employees: (query: QueryFetcher, employee: Employee) => {
            query.whereIn('status', ['approved']);
            query.where('employee_id', employee.id);
        },
    };
    eventType = 'request';

    // Component specific properties

    get monthCalendarTitle(): string {
        return `${this.firstName} ${this.lastName}`;
    }

    get monthCalendarTooltipDescription(): string {
        return this.detail || 'Away';
    }

    get upcomingEventTitle(): string {
        return this.summary;
    }

    get upcomingEventDescription(): string {
        const leaveTypeDescription = this.detail || 'Away';
        if (this.isMultiDayEvent) {
            return `${leaveTypeDescription} from ${this.dateDescription}`;
        }

        return leaveTypeDescription;
    }

    get dateDescription(): string {
        const sameDay = isSameDay(this.startAt, subMinutes(this.endAt, 1));
        if (sameDay) {
            if (getHours(this.startAt) === 0 && getHours(this.endAt) === 12) {
                return 'Morning';
            }

            if (getHours(this.startAt) === 0 && getHours(this.endAt) === 6) {
                return 'Early morning';
            }

            if (getHours(this.startAt) === 6 && getHours(this.endAt) === 12) {
                return 'Late morning';
            }

            if (getHours(this.startAt) === 12 && getHours(this.endAt) === 0) {
                return 'Afternoon';
            }

            if (getHours(this.startAt) === 12 && getHours(this.endAt) === 18) {
                return 'Early afternoon';
            }

            if (getHours(this.startAt) === 18 && getHours(this.endAt) === 0) {
                return 'Late afternoon';
            }

            return `All day`;
        }

        // only show the month on the end date if it's different that the month on the start date
        const secondDateFormat = isSameMonth(this.startAt, this.endAt) ? 'D' : 'MMM D';
        return `${format(this.startAt, 'MMM D')} to ${format(subMinutes(this.endAt, 1), secondDateFormat)}`;
    }

    get path(): (string | number)[] {
        return ['/', 'time-off', 'requests', this.entityId];
    }
}
