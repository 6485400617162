import { Paginator } from '@app/classes/paginator';

export abstract class IndexView {
    isLoading: boolean;
    paginator: Paginator;

    onLoadingError(_err: Error): void {}

    /**
     * Paginator/Entities Loaded Event
     */
    protected onLoad(): void {}
}
